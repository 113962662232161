class InvestmentsList {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;

        this.root = el;
        this.gridEl = this.root.querySelector('[data-investments-list-grid]');
        this.moreButtonEl = this.root.querySelector(
            '[data-investments-list-more]'
        );
        this.gridItems = this.root.querySelectorAll('.investmenst-list__slide');

        this.gridHTML = '';
        this.currPage = 1;

        return true;
    }

    init() {
        this.setGrid();
    }

    setGrid() {
        if (!this.gridEl) return;
        this.gridHTML = this.gridEl.innerHTML;

        this.setMoreButton();
        this.setCardsAos();
    }

    setMoreButton() {
        if (!this.moreButtonEl) return;

        this.onMore = this.onMoreClick.bind(this);
        this.moreButtonEl.addEventListener('click', this.onMore);
    }

    onMoreClick(event) {
        const { currentTarget } = event;
        this.queryArgs = JSON.parse(
            currentTarget.getAttribute('data-query-args')
        );
        this.settings = JSON.parse(currentTarget.getAttribute('data-settings'));

        this.currPage += 1;
        if (this.currPage > this.queryArgs.max_num_pages) return;

        this.loadInvestments();

        if (this.currPage >= this.queryArgs.max_num_pages) {
            this.gridEl.classList.add('-last-page');
            this.moreButtonEl.parentElement.classList.add('d-none');
        }
    }

    async loadInvestments() {
        const formData = new FormData();
        this.gridEl.classList.add('is-loading');
        formData.append('query_args', JSON.stringify(this.queryArgs));
        formData.append('settings', JSON.stringify(this.settings));
        formData.append('page', this.currPage);

        const request = await fetch(`${home_url}/wp-json/investments/v1/list`, {
            method: 'POST',
            body: formData,
        });

        const res = await request.json();
        const { html } = res;

        if (html) this.gridEl.innerHTML += html;
        this.gridEl.classList.remove('is-loading');
    }

    setCardsAos() {
        this.onAosResize = this.onAosResizeHandler.bind(this);
        window.addEventListener('resize', this.onAosResize);
    }

    onAosResizeHandler(event) {
        const { innerWidth } = window;
        if (!this.gridItems) return;

        this.gridItems.forEach(item => {
            if (innerWidth > 768) item.classList.add('aos-init');
        });
    }
}

function initInvestmentsList() {
    const sections = document.querySelectorAll('[data-investments-list]');
    if (!sections) return;

    sections.forEach(item => new InvestmentsList(item));
}

if (
    document.readyState === 'complete' ||
    (document.readyState !== 'loading' && !document.documentElement.doScroll)
) {
    initInvestmentsList();
} else {
    document.addEventListener('DOMContentLoaded', initInvestmentsList);
}
